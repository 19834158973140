import React from "react";
import Seo from "../components/Seo/Seo";
import styled from "styled-components";
import { Box } from "../components/Core";
import { device } from "../utils";
import { Container } from "react-bootstrap";

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  min-height: 100vh;
`;

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  border-radius: 10px;
  width: 100%;
`;

const Speedtest = (props) => {
    return (
        <div>
            <Seo
                title="Youfibre - Speedtest"
                description="Youfibre - Speedtest"
            />

            <BoxStyled bg="#f7f8fa">
                <BoxInner className="d-flex align-items-center">
                    <Container>
                        <Box my="150px" mx="auto">
                            <FormStyled>
                                <iframe width="100%" height="650px" frameBorder="0" src="https://youfibre.speedtestcustom.com" />
                            </FormStyled>
                        </Box>
                    </Container>
                </BoxInner>
            </BoxStyled>
        </div>
    )
}

export default Speedtest